import { Box } from "@mui/material";
import niroggyan from "../assets/niroggyanWhite.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#3567D6",
        padding: "12px 32px",
        width: "100%",
      }}
    >
      <Link to="/">
        <img src={niroggyan} alt="logo" height="30px" />
      </Link>
    </Box>
  );
};

export default Navbar;
