import React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { CircularProgress, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import ReactGA from "react-ga4";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useLocation } from "react-router-dom";
import HappyFace from "../../HappyFace.png";
import SadFace from "../../SadFace.png";
import useGAEventsTracker from "../../GaEventTracker";

const HeartRiskForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    clientId,
    labNo,
    patient_age,
    patient_gender,
    total_cholesterol,
    cholesterol_unit,
    hdl,
    hdl_unit,
    sbp,
    smoking,
    diabetes,
  } = Object.fromEntries(queryParams);
  const [age, setAge] = useState(patient_age ? patient_age : "");
  const [gender, setGender] = useState(
    patient_gender ? patient_gender : "male"
  );
  const [totalCholesterol, setTotalCholesterol] = useState(
    total_cholesterol || ""
  );
  const [hdlCholesterol, setHdlCholesterol] = useState(hdl || "");
  const [hdlCholesterolUnit, setHdlCholesterolUnit] = useState(
    hdl_unit || "mg/dl"
  );
  const [totalCholesterolUnit, setTotalCholesterolUnit] = useState(
    cholesterol_unit || "mg/dl"
  );
  const [sbpValue, setSbpValue] = useState(sbp || "");
  const [sbpUnit, setSbpUnit] = useState("mm Hg");
  const [smokingStatus, setSmokingStatus] = useState(
    smoking ? JSON.parse(smoking) : false
  );
  const [diabeticStatus, setDiabeticStatus] = useState(
    diabetes ? JSON.parse(diabetes) : false
  );
  const [sbpStatus, setSbpStatus] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkBoxError, setCheckBoxError] = useState(false);

  const [riskScore, setRiskScore] = useState("");
  const [loader, setLoader] = useState(false);
  console.log("asas", Object.fromEntries(queryParams));

  const navigate = useNavigate();
  const GAEventsTracker = useGAEventsTracker("risk score form page");

  const questionDetails = {
    sbp: {
      question:
        "Have you been diagnosed with high blood pressure, and are you currently on any treatment or medication for it?",
      options: [
        { title: "Yes", value: true },
        { title: "No", value: false },
      ],
    },
    smoking: {
      question: "Do you smoke?",
      options: [
        { title: "Yes", value: true },
        { title: "No", value: false },
      ],
    },
    diabetic: {
      question: "Are you Diabetic?",
      options: [
        { title: "Yes", value: true },
        { title: "No", value: false },
      ],
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!checked) {
      setCheckBoxError("Please check this box");
      return;
    }

    if (checked) {
      setCheckBoxError("");
    }
    setLoader(true);
    const url = `https://6ohh9xcizb.execute-api.ap-south-1.amazonaws.com/prod/?riskType=heart`;

    const payload = {
      clientId: clientId,
      labNo: labNo,
      age: age,
      gender: gender,
      results: [
        {
          name: "total cholesterol",
          value: totalCholesterol,
          unit: totalCholesterolUnit,
        },
        {
          name: "hdl",
          value: hdlCholesterol,
          unit: hdlCholesterolUnit,
        },
        {
          name: "sbp",
          value: sbp,
          unit: "mm Hg",
          treated: sbpStatus,
        },
      ],
      lifestyle: {
        smoking: smokingStatus,
        diabetes: diabeticStatus,
      },
    };

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log("response", data);
      if (data.isSuccess && data?.data?.risk) {
        GAEventsTracker("submitted", "successfully", "heart risk");
        GAEventsTracker("risk score data", data.data.risk, "heart risk");
        setRiskScore(data.data.risk);
        Swal.fire({
          iconHtml: `<img src=${HappyFace}>`,
          customClass: {
            icon: "no-border",
          },
          width: "400px",
          title: "Success!",
          text: "Your risk score has been generated successfully.",
        });
        navigate("/riskScore", {
          state: { riskData: data.data.risk, riskType: "heart" },
        });
      } else {
        GAEventsTracker("submission", "unsuccessful", "heart risk");
        Swal.fire({
          iconHtml: `<img src=${SadFace}>`,
          customClass: {
            icon: "no-border",
          },
          width: "400px",
          title: "Error",
          text: "Something went wrong. Pleasse try again.",
        });
      }
    } catch (error) {
      console.log("Risk score error", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (checked) {
      setCheckBoxError("");
    }
  }, [checked]);

  return (
    <Stack
      sx={{ padding: { md: "30px", sm: "20px", xs: "0px" } }}
      direction="row"
      justifyContent={"center"}
    >
      <Box
        p={4}
        sx={{
          width: { md: "80%", sm: "100%", xs: "100%" },
          background: "#EDF9FE",
          borderRadius: "16px",
        }}
      >
        <Typography variant="h5">Basic Information</Typography>
        <form onSubmit={handleSubmit}>
          <Stack direction={"column"} gap={2.5} mt={3}>
            <Stack
              gap={3}
              sx={{
                flexDirection: { md: "row", sm: "column", xs: "column" },
                alignItems: {
                  md: "center",
                  sm: "flex-start",
                  xs: "flex-start",
                },
              }}
            >
              <Box>
                <TextField
                  fullWidth
                  size={"small"}
                  label="Age"
                  placeholder="age should be between 20-75"
                  variant="outlined"
                  onChange={(e) => setAge(e.target.value)}
                  value={age}
                  required
                  maxWidth="sm"
                />
              </Box>
              <Box>
                <FormControl
                  onChange={(e) => setGender(e.target.value)}
                  value={gender}
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <FormLabel sx={{ marginRight: "16px", width: "70px" }}>
                    Gender
                  </FormLabel>
                  <RadioGroup row defaultValue={gender}>
                    <FormControlLabel
                      value="male"
                      control={<Radio sx={{ padding: "6px" }} size="small" />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio sx={{ padding: "6px" }} size="small" />}
                      label="Female"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Stack>
            <Stack
              gap={4}
              sx={{
                flexDirection: { md: "row", sm: "column", xs: "column" },
                alignItems: {
                  md: "center",
                  sm: "flex-start",
                  xs: "flex-start",
                },
              }}
            >
              <Box flexGrow={1}>
                <Typography>Total Cholesterol</Typography>
                <Stack direction={"row"} alignItems={"center"} gap={1} mt={0.5}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Value"
                    placeholder="Enter Value"
                    variant="outlined"
                    onChange={(e) => setTotalCholesterol(e.target.value)}
                    value={totalCholesterol}
                    required
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label="Unit"
                    placeholder="Enter Unit"
                    variant="outlined"
                    onChange={(e) => setTotalCholesterolUnit(e.target.value)}
                    value={totalCholesterolUnit}
                    required
                  />
                </Stack>
              </Box>
              <Box flexGrow={1}>
                <Typography>HDL Cholesterol</Typography>
                <Stack direction={"row"} alignItems={"center"} gap={1} mt={0.5}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Value"
                    placeholder="Enter Value"
                    variant="outlined"
                    onChange={(e) => setHdlCholesterol(e.target.value)}
                    value={hdlCholesterol}
                    required
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label="Unit"
                    placeholder="Enter Unit"
                    variant="outlined"
                    onChange={(e) => setHdlCholesterolUnit(e.target.value)}
                    value={hdlCholesterolUnit}
                    required
                  />
                </Stack>
              </Box>
            </Stack>
            <Box>
              <Box mb={2}>
                <Typography>Systolic BP</Typography>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1}
                  mt={0.5}
                  sx={{ width: { md: "48%", sm: "100%", xs: "100%" } }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    label="Value"
                    placeholder="Enter Value"
                    variant="outlined"
                    onChange={(e) => setSbpValue(e.target.value)}
                    value={sbpValue}
                    required
                    // helperText="if your BP is 120/80, just fill 120"
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label="Unit"
                    placeholder="Enter Unit"
                    variant="outlined"
                    onChange={(e) => setSbpUnit(e.target.value)}
                    value={sbpUnit}
                    required
                  />
                </Stack>
              </Box>
              <FormControl required>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{ color: "#3567D6" }}
                >
                  {questionDetails.sbp.question}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={sbpStatus}
                  name="radio-buttons-group"
                  onChange={(e) => setSbpStatus(e.target.value)}
                  row
                >
                  {questionDetails.sbp.options.map((each) => (
                    <FormControlLabel
                      key={each.title}
                      value={each.value}
                      control={<Radio size="small" />}
                      label={each.title}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl required>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{ color: "#3567D6" }}
                >
                  {questionDetails.smoking.question}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={smokingStatus}
                  name="radio-buttons-group"
                  onChange={(e) => setSmokingStatus(e.target.value)}
                  row
                >
                  {questionDetails.smoking.options.map((each) => (
                    <FormControlLabel
                      key={each.title}
                      value={each.value}
                      control={<Radio size="small" />}
                      label={each.title}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl required>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{ color: "#3567D6" }}
                >
                  {questionDetails.diabetic.question}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={diabeticStatus}
                  name="radio-buttons-group"
                  onChange={(e) => setDiabeticStatus(e.target.value)}
                  row
                >
                  {questionDetails.diabetic.options.map((each) => (
                    <FormControlLabel
                      key={each.title}
                      value={each.value}
                      control={<Radio size="small" />}
                      label={each.title}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <Stack direction="row" alignItems="center">
                <Checkbox
                  onChange={() => setChecked(!checked)}
                  checked={checked}
                />
                <Typography variant="p">
                  I acknowledge & agree that I’m sharing metabolic data for
                  accurate results.
                </Typography>
              </Stack>
              {checkBoxError && (
                <Typography
                  pl={5}
                  variant="p"
                  sx={{ color: "red", fontSize: "12px" }}
                >
                  {checkBoxError}
                </Typography>
              )}
            </Box>
            <Box>
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{ width: { md: "40%", sm: "100%", xs: "100%" } }}
              >
                {loader ? (
                  <CircularProgress sx={{ color: "#fff" }} size="30px" />
                ) : (
                  "Get Heart Risk Score"
                )}
              </Button>
            </Box>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
};

export default HeartRiskForm;
