import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UserForm from "./components/UserForm";
import ScorePage from "./components/ScorePage";
import ReactGA from "react-ga4";
import useGAEventsTracker from "./GaEventTracker";
import UserDetails from "./components/FormData/UserDetails";
import Navbar from "./components/Navbar";
import RiskScorePage from "./components/RiskScorePage";
ReactGA.initialize("G-5JCN7SR63C");

function App() {
  const GAEventsTracker = useGAEventsTracker("risk score portal");
  return (
    <Router>
      {GAEventsTracker("visited", "successfully")}
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" exact Component={UserDetails} />
          <Route path="/riskScore" exact Component={RiskScorePage} />
          {/* <Route path="/" exact Component={UserForm} /> */}

          {/* <Route path="/riskScore" exact Component={ScorePage} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
