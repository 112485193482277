import React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import ReactGA from "react-ga4";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useLocation } from "react-router-dom";
import HappyFace from "../../HappyFace.png";
import SadFace from "../../SadFace.png";
import useGAEventsTracker from "../../GaEventTracker";
import CloseIcon from "@mui/icons-material/Close";

const DiabetesRiskForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    clientId,
    labNo,
    patient_age,
    patient_gender,
    systolic,
    systolic_unit,
    diastolic,
    diastolic_unit,
    pulse,
    bmi,
    urban,
  } = Object.fromEntries(queryParams);
  const [age, setAge] = useState(patient_age ? patient_age : "");
  const [gender, setGender] = useState(
    patient_gender ? patient_gender : "male"
  );
  const [systolicValue, setSystolicValue] = useState(systolic || "");
  const [diastolicValue, setDiastolicValue] = useState(diastolic || "");
  const [systolicUnit, setSystolicUnit] = useState(systolic_unit || "mm Hg");
  const [diastolicUnit, setDiastolicUnit] = useState(diastolic_unit || "mm Hg");
  const [pulseValue, setPulseValue] = useState(pulse || "");
  const [bmiValue, setBmiValue] = useState(bmi || "");
  const [urbanStatus, setUrbanStatus] = useState(
    urban ? JSON.parse(urban) : false
  );

  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");

  const [checked, setChecked] = useState(false);
  const [checkBoxError, setCheckBoxError] = useState(false);
  const [open, setOpen] = useState(false);

  const [riskScore, setRiskScore] = useState("");
  const [loader, setLoader] = useState(false);
  console.log("asas", Object.fromEntries(queryParams));

  const navigate = useNavigate();
  const GAEventsTracker = useGAEventsTracker("risk score form page");

  const questionDetails = {
    pulse: {
      question:
        " Are you currently receiving treatment for pulse-related conditions?",
      options: [
        { title: "Yes, my pulse is being treated", value: true },
        { title: "No, my pulse is not being treated", value: false },
      ],
    },
    urban: {
      question: "Do you belong to an urban area?",
      options: [
        { title: "Yes", value: true },
        { title: "No", value: false },
      ],
    },
    bmi: {
      question: "Are you undergoing treatment to manage your BMI?",
      options: [
        { title: "Yes, my BMI is being treated", value: true },
        { title: "No, my BMI is not being treated", value: false },
      ],
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const calculateBMI = (e) => {
    e.preventDefault();
    let heightInMeters = height / 100; // Convert cm to meters
    let bmi = weight / (heightInMeters * heightInMeters);
    if (bmi) {
      bmi = bmi.toFixed(2);
      setBmiValue(bmi);
    } else {
      setBmiValue("");
    }
  };

  const dialogBox = () => {
    return (
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Typography variant="h6">BMI Calculator</Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <form onSubmit={calculateBMI}>
            <Stack direction={"row"} alignItems={"center"} gap={3} pt={1}>
              <TextField
                fullWidth
                size="small"
                label="Height (cm)"
                placeholder="Height in cm"
                variant="outlined"
                onChange={(e) => setHeight(e.target.value)}
                value={height}
                required
              />
              <TextField
                fullWidth
                size="small"
                label="Weight (kg)"
                placeholder="Weight in kg"
                variant="outlined"
                onChange={(e) => setWeight(e.target.value)}
                value={weight}
                required
              />
            </Stack>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="submit"
              >
                Calculate BMI
              </Button>
              {bmiValue ? <Typography mt={1}>BMI: {bmiValue}</Typography> : ""}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!checked) {
      setCheckBoxError("Please check this box");
      return;
    }

    if (checked) {
      setCheckBoxError("");
    }
    setLoader(true);
    const url = `https://6ohh9xcizb.execute-api.ap-south-1.amazonaws.com/prod/?riskType=diabetes`;

    const payload = {
      clientId: clientId,
      labNo: labNo,
      age: age,
      gender: gender,
      results: [
        {
          name: "Systolic",
          value: systolicValue,
          unit: systolicUnit,
        },
        {
          name: "Diastolic",
          value: diastolicValue,
          unit: diastolicUnit,
        },
        {
          name: "Body Mass Index(BMI)",
          value: bmiValue,
          unit: "",
        },
        {
          name: "Pulse",
          value: pulseValue,
          unit: "",
        },
      ],
      lifestyle: {
        isUrban: urbanStatus,
      },
    };

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      console.log("response", data);
      if (data.isSuccess && data?.data?.risk) {
        GAEventsTracker("submitted", "successfully", "diabetes risk");
        GAEventsTracker("risk score data", data.data.risk, "diabetes risk");
        setRiskScore(data.data.risk);
        Swal.fire({
          iconHtml: `<img src=${HappyFace}>`,
          customClass: {
            icon: "no-border",
          },
          width: "400px",
          title: "Success!",
          text: "Your risk score has been generated successfully.",
        });
        navigate("/riskScore", {
          state: { riskData: data.data.risk, riskType: "diabetes" },
        });
      } else {
        GAEventsTracker("submission", "unsuccessful");
        Swal.fire({
          iconHtml: `<img src=${SadFace}>`,
          customClass: {
            icon: "no-border",
          },
          width: "400px",
          title: "Error",
          text: "Something went wrong. Pleasse try again.",
        });
      }
    } catch (error) {
      console.log("Risk score error", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (checked) {
      setCheckBoxError("");
    }
  }, [checked]);

  return (
    <Stack
      sx={{ padding: { md: "30px", sm: "20px", xs: "0px" } }}
      direction="row"
      justifyContent={"center"}
    >
      <Box
        p={4}
        sx={{
          width: { md: "80%", sm: "100%", xs: "100%" },
          background: "#EDF9FE",
          borderRadius: "16px",
        }}
      >
        <Typography variant="h5">Basic Information</Typography>
        <form onSubmit={handleSubmit}>
          <Stack direction={"column"} gap={2.5} mt={3}>
            <Stack
              gap={3}
              sx={{
                flexDirection: { md: "row", sm: "column", xs: "column" },
                alignItems: {
                  md: "center",
                  sm: "flex-start",
                  xs: "flex-start",
                },
              }}
            >
              <Box>
                <TextField
                  fullWidth
                  size={"small"}
                  label="Age"
                  placeholder="age should be between 20-75"
                  variant="outlined"
                  onChange={(e) => setAge(e.target.value)}
                  value={age}
                  required
                  maxWidth="sm"
                />
              </Box>
              <Box>
                <FormControl
                  onChange={(e) => setGender(e.target.value)}
                  value={gender}
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <FormLabel sx={{ marginRight: "16px", width: "70px" }}>
                    Gender
                  </FormLabel>
                  <RadioGroup row defaultValue={gender}>
                    <FormControlLabel
                      value="male"
                      control={<Radio sx={{ padding: "6px" }} size="small" />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio sx={{ padding: "6px" }} size="small" />}
                      label="Female"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Stack>
            <Stack
              gap={3}
              sx={{
                flexDirection: { md: "row", sm: "column", xs: "column" },
                alignItems: {
                  md: "center",
                  sm: "flex-start",
                  xs: "flex-start",
                },
              }}
            >
              <Box flexGrow={1}>
                <Typography>Systolic BP</Typography>
                <Stack direction={"row"} alignItems={"center"} gap={0.5} mt={1}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Value"
                    placeholder="Enter Value"
                    variant="outlined"
                    onChange={(e) => setSystolicValue(e.target.value)}
                    value={systolicValue}
                    required
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label="Unit"
                    placeholder="Enter Unit"
                    variant="outlined"
                    onChange={(e) => setSystolicUnit(e.target.value)}
                    value={systolicUnit}
                    required
                  />
                </Stack>
              </Box>
              <Box flexGrow={1}>
                <Typography>Diastolic BP</Typography>
                <Stack direction={"row"} alignItems={"center"} gap={0.5} mt={1}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Value"
                    placeholder="Enter Value"
                    variant="outlined"
                    onChange={(e) => setDiastolicValue(e.target.value)}
                    value={diastolicValue}
                    required
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label="Unit"
                    placeholder="Enter Unit"
                    variant="outlined"
                    onChange={(e) => setDiastolicUnit(e.target.value)}
                    value={diastolicUnit}
                    required
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack direction={"column"} gap={2}>
              <Box flexGrow={1}>
                <TextField
                  fullWidth
                  size="small"
                  label="Pulse"
                  placeholder="Enter your pulse value"
                  variant="outlined"
                  onChange={(e) => setPulseValue(e.target.value)}
                  value={pulseValue}
                  required
                  sx={{ width: { md: "40%", sm: "100%", xs: "100%" } }}
                />
              </Box>
            </Stack>

            <Stack direction={"column"} gap={2}>
              <Box
                flexGrow={1}
                sx={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <TextField
                  fullWidth
                  size="small"
                  label="BMI"
                  placeholder="BMI"
                  variant="outlined"
                  onChange={(e) => setBmiValue(e.target.value)}
                  value={bmiValue}
                  required
                  sx={{ width: { md: "40%", sm: "50%", xs: "50%" } }}
                />
                <Typography
                  onClick={handleClickOpen}
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "gray",
                  }}
                >
                  Calculate BMI
                </Typography>
              </Box>

              {dialogBox()}
            </Stack>

            <Box>
              <FormControl required>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{ color: "#3567D6" }}
                >
                  {questionDetails.urban.question}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={urbanStatus}
                  name="radio-buttons-group"
                  onChange={(e) => setUrbanStatus(e.target.value)}
                  row
                >
                  {questionDetails.urban.options.map((each) => (
                    <FormControlLabel
                      key={each.title}
                      value={each.value}
                      control={<Radio size="small" />}
                      label={each.title}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>

            <Box>
              <Stack direction="row" alignItems="center">
                <Checkbox
                  onChange={() => setChecked(!checked)}
                  checked={checked}
                />
                <Typography variant="p">
                  I acknowledge & agree that I’m sharing metabolic data for
                  accurate results.
                </Typography>
              </Stack>
              {checkBoxError && (
                <Typography
                  pl={5}
                  variant="p"
                  sx={{ color: "red", fontSize: "12px" }}
                >
                  {checkBoxError}
                </Typography>
              )}
            </Box>
            <Box>
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{ width: { md: "40%", sm: "100%", xs: "100%" } }}
              >
                {loader ? (
                  <CircularProgress sx={{ color: "#fff" }} size="30px" />
                ) : (
                  "Get Diabetes Risk Score"
                )}
              </Button>
            </Box>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
};

export default DiabetesRiskForm;
