import { Box, Stack, Typography } from "@mui/material";
import GaugeComponent from "react-gauge-component";

const subArcDetails = {
  heart: {
    ranges: [
      {
        limit: 9,
        color: "#09b520",
        showTick: true,
        tooltip: {
          text: "Low Risk",
        },
      },
      {
        limit: 19,
        color: "#fc5908",
        showTick: true,
        tooltip: {
          text: "Intermediate Risk",
        },
      },
      {
        limit: 100,
        color: "#fc0808",
        showTick: true,
        tooltip: {
          text: "High Risk",
        },
      },
    ],
  },
  diabetes: {
    ranges: [
      {
        limit: 10,
        color: "#09b520",
        showTick: true,
        tooltip: {
          text: "Low Risk",
        },
      },
      {
        limit: 34,
        color: "#fc5908",
        showTick: true,
        tooltip: {
          text: "Moderate Risk",
        },
      },
      {
        limit: 100,
        color: "#fc0808",
        showTick: true,
        tooltip: {
          text: "High Risk",
        },
      },
    ],
  },
};

const ScoreGauge = ({ riskData, riskType }) => {
  const getSubArcsArray = () => {
    let result = [];
    switch (riskType) {
      case "heart":
        result = subArcDetails[riskType].ranges;
        break;
      case "diabetes":
        result = subArcDetails[riskType].ranges;
        break;
      default:
        break;
    }
    return result;
  };

  const getScore = () => {
    let value = "";
    switch (riskType) {
      case "heart":
        value =
          riskData?.score && riskData.score.includes("%")
            ? parseFloat(riskData.score.split("%")[0])
            : parseFloat(riskData.score);
        break;
      case "diabetes":
        value = riskData?.score ? riskData.score : "";
      default:
        break;
    }
    console.log("adad", value);
    return value;
  };

  return (
    <Box sx={{ background: "#EDF9FE" }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={1}
        p={1}
        sx={{ background: "#e9ecef", borderRadius: "8px" }}
      >
        <Typography sx={{ color: "#464646" }}>Your Score:</Typography>
        <Typography sx={{ color: "#000000", fontWeight: "bold" }}>
          {riskData.score} {`(${riskData.label})`}
        </Typography>
      </Stack>
      <Box sx={{ width: { sm: "400px", xs: "300px" } }}>
        <GaugeComponent
          type="semicircle"
          arc={{
            width: 0.2,
            padding: 0.005,
            cornerRadius: 1,
            // gradient: true,
            subArcs: getSubArcsArray(),
          }}
          pointer={{
            type: "arrow",
            color: "#000000",
            length: 0.8,
            width: 15,
            //   elastic: true,
          }}
          labels={{
            valueLabel: {
              matchColorWithArc: true,
              maxDecimalDigits: 1,
              style: {
                fontSize: "30px",
                textShadow: "2px 4px 3px rgba(0, 0, 0, 0.1)",
                fontWeight: 600,
              },
            },

            //   valueLabel: { formatTextValue: (value) => value + "\n Low Risk" },
            tickLabels: {
              type: "outer",
              defaultTickValueConfig: {
                //   formatTextValue: (value: any) => value + "ºC",
                style: { fontSize: 10, fill: "#000000" },
              },
              defaultTickLineConfig: {
                color: "#000000",
              },
            },
          }}
          value={getScore()}
          minValue={0}
          maxValue={100}
        />
      </Box>
    </Box>
  );
};

export default ScoreGauge;
