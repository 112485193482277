import { Box } from "@mui/material";
import HeartRiskForm from "./HeartRiskForm";
import DiabetesRiskForm from "./DiabetesRiskForm";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const UserDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { riskType, clientId, labNo } = Object.fromEntries(queryParams);

  const getSuitableComponent = () => {
    switch (riskType) {
      case "heart":
        return <HeartRiskForm />;
      case "diabetes":
        return <DiabetesRiskForm />;
      default:
        return <HeartRiskForm />;
    }
  };

  const fetchStoredRiskScore = async () => {
    const apiUrl = `https://6ohh9xcizb.execute-api.ap-south-1.amazonaws.com/prod/?riskType=${riskType}&clientId=${clientId}&labNo=${labNo}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      console.log("sassd", data);
      if (data.isSuccess) {
        if (data.data.heartRisk && riskType === "heart") {
          console.log("go to risk page");
          navigate("/riskScore", {
            state: { riskData: data.data.heartRisk.risk, riskType: "heart" },
          });
        }
        if (data.data.diabetesRisk && riskType === "diabetes") {
          navigate("/riskScore", {
            state: {
              riskData: data.data.diabetesRisk.risk,
              riskType: "diabetes",
            },
          });
        }
      }
    } catch (e) {
      console.log("Error fetching data", e);
    }
  };

  useEffect(() => {
    fetchStoredRiskScore();
  }, []);

  return <Box>{getSuitableComponent()}</Box>;
};

export default UserDetails;
